/**
 * Here goes all the JS Code you need in your child theme buddy!
 */

// http://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed

var waitForFinalEvent = (function () {
  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "Don't call this twice without a uniqueId";
    }
    if (timers[uniqueId]) {
      clearTimeout (timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();

var paddingTop = 0; // how much room will be left on top of the element when scrolled there

// Superfish menüü
// jQuery(document).ready(function($) {
//  $('.header-menu').superfish({
//    delay: 100,
//    speed: 'fast',
//    animation: { opacity:'show',height:'show' }
//  });
// });

// CTA riba

jQuery(document).ready(function ($) {

  var cord = jQuery(window).scrollTop();

  var windowheight = jQuery(window).height();

  var fixelement = $("#cta-menu");

  var fixelementheight = fixelement.height();

  fixpointposition = jQuery("#footer").position();

  if (cord < fixpointposition.top) {
        fixelement.addClass("fixed");
  }

  $( window ).resize(function() {
    waitForFinalEvent(function(){
          windowheight = jQuery(window).height();
          cord = windowheight + cord;

          fixpointposition = jQuery("#footer").position();

          if (cord < fixpointposition.top) {
              fixelement.addClass("fixed");
          }
        }, 2000, "windowResize");

  });


  jQuery(document).scroll(function(){
    var cord = jQuery(window).scrollTop();
    cord = windowheight + cord;
    fixpointposition = jQuery("#footer").position();
    if (cord < fixpointposition.top) {
      fixelement.addClass("fixed");
    }
    else {
      fixelement.removeClass("fixed");
    }
  });
});

// Single-Model navigation movement

jQuery(window).load(function($){

  if ($("#page-menu")) {
    var timeoutScroll = null;

    var cord = jQuery(window).scrollTop();

    if (cord > 120) {
        $("#page-menu").addClass("fixed");
    }

    jQuery(document).scroll(function(){

      var cord = jQuery(window).scrollTop();

      if (cord > 120) {
        $("#page-menu").addClass("fixed");
      }
      else {
        $("#page-menu").removeClass("fixed");
      }
    });

    // Smooth scroll to model page paragraphs
    $("#page-menu a").on("click", function(event){
      event.preventDefault();

      // Get the hash. In this example, "#myDestinationAnchor".
      var targetSelector = this.hash;
      var $target = $(targetSelector);
      // Animate the scroll to the destination...
      $('html, body').animate(
          {
              scrollTop: $target.offset().top // Scroll to this location.
          }, {
              // Set the duration long enough to allow time
              // to lazy load the elements.
              duration: 2000,

              // At each animation step, check whether the target has moved.
              step: function( now, fx ) {

                  // Where is the target now located on the page?
                  // i.e. its location will change as images etc. are lazy loaded
                  var newOffset = $target.offset().top;

                  // If where we were originally planning to scroll to is not
                  // the same as the new offset (newOffset) then change where
                  // the animation is scrolling to (fx.end).
                  if(fx.end !== newOffset)
                      fx.end = newOffset;
              }
          }
      );
    });

    // Modern version that does not work with lazi loading
    // var modelPageNavigation = document.getElementById('menu-sub-menu');

    // for (var i = 0; i < modelPageNavigation.children.length; i++) {
    //   console.log(modelPageNavigation.children[i].tagName);
    //     var listItem = modelPageNavigation.children[i];
    //     var link = listItem.children[0];
    //     link.addEventListener('click', function (e) {
    //     e.preventDefault();

    //     document.querySelector(this.getAttribute('href')).scrollIntoView({
    //       behavior: 'smooth'
    //     });
    //   });
    // }

    // Smooth scroll to anchor inside url when page is loaded

    if (document.location.hash.length !== 0 && !document.location.hash.includes('gid') && !document.location.hash.includes('offer')&& !document.location.hash.includes('service') && !document.location.hash.includes('testdrive') && !document.location.hash.includes('petrol') && !document.location.hash.includes('diesel') && !document.location.hash.includes('electric') && !document.location.hash.includes('phev') ) {
      // Get the hash. In this example, "#myDestinationAnchor".
      var targetSelector = document.location.hash;
      var $target = $(targetSelector);
      // Animate the scroll to the destination...
      $('html, body').animate(
          {
              scrollTop: $target.offset().top // Scroll to this location.
          }, {
              // Set the duration long enough to allow time
              // to lazy load the elements.
              duration: 2000,

              // At each animation step, check whether the target has moved.
              step: function( now, fx ) {

                  // Where is the target now located on the page?
                  // i.e. its location will change as images etc. are lazy loaded
                  var newOffset = $target.offset().top;

                  // If where we were originally planning to scroll to is not
                  // the same as the new offset (newOffset) then change where
                  // the animation is scrolling to (fx.end).
                  if(fx.end !== newOffset)
                      fx.end = newOffset;
              }
          }
      );
    }
  }


}(jQuery));

// CTA riba tabid

jQuery(document).ready(function ($) {
    // Eemaldab esimeselt CTA tabilt vaikimisi active staatuse
  $("#cta-menu .tabs dd").each(function() {
    $(this).removeClass('active');
  });

  // Aktiivsele CTA elemendile vajutades eemaldab .active klassi

  $("#cta-menu dd.active").click( function(event){
      $(this).toggleClass('active');
      var tabName = $(this).children().attr('href').substr(1);
      tabID = tabName+"Tab";
      $("#cta-menu #"+tabID).toggle();
  });

  // Aktiivse vormi sulgemine

  $("#tabClose").click( function(){
    $("#cta-menu .tabs-content").find("li.active").removeClass("active").hide();
  });
});

jQuery(document).ready(function ($) {
    // .smooth klassiga nuppudele vajutades kerib sujuvalt soovitud elemendini
  $(".smooth").on("click", function(event){
    event.preventDefault();
    var scrollToY = ($(this.hash).length) ? $(this.hash).offset().top - paddingTop : 0;
    $("html,body").animate({scrollTop:scrollToY}, 700);
  });
});

// Paketi juures päringunupule vajutamine
jQuery(document).ready(function($) {
  $('.packoffer').click(function(){
    var packname = $(this).data('packname');
    $("#offerTab").data('packname',packname);

    var button = $('#offerTabButton');
    var content = $('#offerTab');

    openTab(button,content);
    populateModels();

    if ($("#modelConfigurations option[value='"+packname+"']").length !== 0) {
      $('#modelConfigurations').val(packname);
    }

  });
});


// "Küsi pakkumist" ja "Tule proovisõidule" vormide saatmisel asendab valitud mudeli ja paketi value tekstiga
jQuery(document).ready(function($) {
  $("#offerSubmit").hover(function() {
    $( "#models option:selected" ).val(function() {
      return $(this).text();
    });
    $( "#modelConfigurations option:selected" ).val(function() {
      return $(this).text();
    });
  });

  $("#testdriveSubmit").hover(function() {
    $( "#testdrivemodels option:selected" ).val(function() {
      return $(this).text();
    });
    $( "#modelConfigurations option:selected" ).val(function() {
      return $(this).text();
    });
  });
});


//Küsi pakkumist dünaamiline vorm

jQuery(document).ready(function ($) {

  if ($('.close')) {
    $('.close').click(function() {
      $(this).parent().toggle();
    });
  }

});

// Animeerib varustustabelit

jQuery(document).ready(function ($) {

  var table = $('#technical-content');

    $('#expandtable').click(function() {
      var seeMore = $(this).data('see-more');
      var seeLess = $(this).data('see-less');
      if (!table.hasClass("maximized")) {
        // show whole table
        table.addClass("maximized");
        $(this).html(seeLess);
      }
      else {
        // already been clicked once, hide it
        table.removeClass("maximized");
        $(this).html(seeMore);
      }
    });

});

function setScrollerDoubleWith () {
  jQuery(".tabs-content-scroller-content").each(function(){
    jQuery(this).width(function(){
      var tablewidth = jQuery(this).parent().next().children(".active").children("table").width();
      if (tablewidth>1140) {
        return tablewidth+37;
      }
      else {
        return 0;
      }
    });
  });
}

// Lisab varustustabelile kerimisriba ka üles
jQuery(document).ready(function ($) {
  setScrollerDoubleWith();

    $(".tabs-content-scroller").scroll(function(){
        $(this).next(".tabs-content")
          .scrollLeft($(".tabs-content-scroller").scrollLeft());
    });
    $("#technical ul.tabs-content li ul.tabs-content").scroll(function(){
        $(this).prev(".tabs-content-scroller")
          .scrollLeft($(this).scrollLeft());
    });
});

// Andmed/Varustus kütuseliigi järgi

jQuery(document).ready(function ($) {

  $('.fuelSelector').click(function () {
    fuelPieces = $(this).attr('id').split('S');
    fuelType = fuelPieces[0];

    activeTypePieces = $('#type').children('.active').attr('id').split('S');
    activeType = activeTypePieces[0];

    $('#specsSelector').children('a').attr('href','#'+fuelType+'specs');
    $('#equipmentSelector').children('a').attr('href','#'+fuelType+'equipment');

    $('#'+fuelType+activeType+'Tab').addClass('active').show().siblings().hide();
  });

});

// Lisavarustuse kastikeste parandused + Detailsemalt nupu vajutusel kastikese laienemine

jQuery(document).ready(function ($) {

  var headingheight = parseInt($('.accessory-heading').height()) + parseInt($('.accessory-heading').css('padding-bottom'));

  if ($(window).width()>767) {
    // var imageheight = $('.accessory-image').children('img').attr('height');
    // var headingwidth = $('.accessory-heading').width();
    // $('.accessory-image, .accessory-heading').css('height',imageheight);
    // $('.detailed').css('width',headingwidth);
  }

  else {
    $('.accessory-image').each( function () {
      var initialheight = parseInt($(this).children('img').attr('height'));
      var initialwidth = parseInt($(this).children('img').attr('width'));
      var currentwidth = parseInt($(this).children('img').width());
      var currentheight = initialheight * currentwidth / initialwidth;
      $('.accessory-image').css('height',currentheight);
    });
    $('.accessory-heading').css('height','');
  }

  $( window ).resize(function() {
    var imageheight = $('.accessory-image').children('img').height();
    var headingwidth = $('.accessory-heading').width();

    if ($(window).width()>767) {
      $('.accessory-image, .accessory-heading').css('height',imageheight);
      $('.detailed').css('width',headingwidth);

    }
    else {
       $('.accessory-heading').css('height','');
       $('.detailed').css('width','');
       $('.accessory-image').each( function () {
        var initialheight = parseInt($(this).children('img').attr('height'));
        var initialwidth = parseInt($(this).children('img').attr('width'));
        var currentwidth = parseInt($(this).children('img').width());
        var currentheight = initialheight * currentwidth / initialwidth;
        $('.accessory-image').css('height',currentheight);
       });
    }
  });

  $('.detailed').click(function() {
    var accessoryItem = $(this).parent().parent().parent().parent();
    if (accessoryItem.hasClass("open")) {
      $(this).html(function() {
        return $(this).data("text-view");
      });
    }
    else {
      $(this).html(function() {
        return $(this).data("text-hide");
      });
    }
    accessoryItem.toggleClass("open");
    // $(this).parent().parent().parent().parent().toggleClass("open");
    $(this).parent().siblings('.accessory-description').toggleClass('active').slideToggle();

    // var item = $(this).parent();
    //    if (item.hasClass("active")) {
    //      if ($(window).width()>768) {
    //        item.removeClass('active').animate({height: imageheight+'px'}, 'slow');
    //      }
    //      else {
    //        item.removeClass('active').animate({height: headingheight+'px'}, 'slow');
    //      }
    //      $(this).html("Detailsemalt");
    //  }
    // else {
    //   item.addClass('active').animate({height: '330px'}, 'slow');
    //   $(this).html("Vähem");
    // }
  });
});

// Võrdsed kõrgused

jQuery(document).ready(function ($) {

$('.warranty-text, .headings-and-description').matchHeight();

});

// Akna laiune rida hero pildil

jQuery(document).ready(function ($) {

  // Võrdsed asukohad (funktsioon contactTop)
  // (c) Priit Karu 2014
  // Võrdleb järjestikkuste elementide sees olevaid elemente ning kui child elementide paiknemine võrreldes
  // parent elemendiga on omavahel erinev, siis kohaldatakse mõlemale elemendile sama kaugus parent elemendi ülemisest äärest.

  function contactTop() {
      $.each( $('.department'), function() {
        if ($('ul.employees > li', this).length > 1) {
          var length=$('ul.employees > li', this).length;
          var rowsAmount=Math.ceil(length / 2);
          var rowsCounter=0;
          var elementCounter=0;
          for (;rowsCounter<rowsAmount;rowsCounter++,elementCounter+=2) {
            var first=$('ul.employees > li:nth-child(' + (elementCounter+1) +') > div > ul.contacts', this);
            var second=$('ul.employees > li:nth-child(' + (elementCounter+2) +') > div > ul.contacts', this);
            var neighbors=[{element:first, top:first.position().top}, {element:second, top:second.position().top}];
            if (neighbors[0].top!=neighbors[1].top) {
              neighbors.sort(function(a, b) {
                  return a.top-b.top;
              });
              neighbors[0].element.css('padding-top',neighbors[1].top-neighbors[0].top);
            }
          }

        }
      });
    }

  contactTop();

  // Liigutab üksiku eripakkumise lehel mobiilivaates sisuosa eripakkumise juhtlõigu kõrguse võrra allapoole

  if ($(window).width()<1140) {
    $('.windowWidth').width($(window).width());
  }

  $( window ).resize(function() {
    waitForFinalEvent(function(){
      var windowWidth = jQuery(window).width();
      if (windowWidth<1140) {
        $('.windowWidth').width(windowWidth);
      }
      else {
        $('.windowWidth').width(1140);
      }
      contactTop();
    }, 1000, "windowResize");
  });
});

// Laadib fotod ainult siis, kui ekraan on piisavalt lai

jQuery(document).ready(function ($) {

  function loadContactImages() {
    if ($(window).width()>568) {
      $('.employee-image img').each(function () {
        $(this).attr('src', $(this).data('src'));
      });
    }
  }

  loadContactImages();

  $( window ).resize(function() {
      loadContactImages();
  });

});

// Kerib sujuvalt pakettide juurde

jQuery(document).ready(function ($) {
  $("#packs .economywrap a").on("click", function(event){
    event.preventDefault();
    var scrollToY = ($("#technical").length) ? $("#technical").offset().top - paddingTop : 0;
    $("html,body").animate({scrollTop:scrollToY}, 300);
  });
});

// Rippmenüü

jQuery(document).ready(function ($) {
  $("ul.header-menu > li.menu-item-has-children").each(function () {
    var parentWidth = $(this).width();
    $(this).children().children('li').css('min-width',parentWidth+'px');
  });
});

// Kasutatud autode nimekirjas keelemenüü asukoha parandus

jQuery(document).ready(function ($) {
  if (document.getElementById("carlist")) {
    $('#lang_sel_list').css('top','17px');
  }
});

// Klikitav rida andmetabelis

jQuery(document).ready(function($) {
  $(".clickableRow").css("cursor","pointer");
  $(".clickableRow").click(function() {
    window.document.location = $(this).data("url");
  });
});

// Samanimelised mudelid (LANCER) ühte ritta

// jQuery(document).ready(function($) {
//  $('#models').children().children().children('ul').each(function () {
//    $(this).children('.lancer').not(":eq(0)").css({clear: 'none'});
//    $(this).children('.lancer').first().css({clear: 'both'});
//  });
// });


// Lisab <html>ile modern-not-ie klassi, kui tegemist on Chrome, Safari, FF või Operaga


// Scroll to top nupp
jQuery(document).ready(function($){

  //Check to see if the window is top if not then display button
  $(window).scroll(function(){
    if ($(this).scrollTop() > 500) {
      $('#scrollToTop').fadeIn();
    } else {
      $('#scrollToTop').fadeOut();
    }
  });

  //Click event to scroll to top
  $('#scrollToTop').click(function(){
    $('html, body').animate({scrollTop : 0},800);
    return false;
  });

});

// Uue auto lehel slaiderid
jQuery(document).ready(function($){

  if ($("body").hasClass("single-special_offer") || $("body").hasClass("single")) {

    $('.model-colors').owlCarousel({
      margin: 0,
      height: 200,
      autoWidth: false,
      nav: true,
      dots: false
    });

  }
});

// Lisavarustuse tabelis oleva lingi korral terve tabelirida klikitav, link avaneb uuel vahelehel
jQuery(document).ready(function($){
  $('.tech-table tr').hover( function() {
    if ($(this).find('a').length > 0) {
      $(this).toggleClass('hover');
        $(this).click( function() {
          if ($(this).find('a').attr('target')==='_blank') {
            var win = window.open($(this).find('a').attr('href'), '_blank');
            win.focus();
          }
          else {
            window.open($(this).find('a').attr('href'), '_self');
          }
        });
      }
  });
});

function addLoadingIcon() {
  jQuery('#selectOptions').html( '<div id="loading-indicator" class="sqs-spin visible"><div class="sqs-spin-content"></div></div>' );
}
function removeLoadingIcon() {
  jQuery("#loading-indicator").remove();
}

function trackConv(google_conversion_id, google_conversion_label) {
    var image = new Image(1, 1);
    image.src = "//www.googleadservices.com/pagead/conversion/" + google_conversion_id + "/?label=" + google_conversion_label + "&script=0";  
}


// Fixed CTA'dele vajutamine Ajax käivitused, GA-events jms
jQuery(document).ready(function($) {
  // Kui lehe laadimisel on pakkumise vorm aktiivne
  if ($("#offerTab").hasClass("active")) {
    jQuery("#selectOptions").empty();
    addLoadingIcon();
    populateModels();
  }

  // Kui vajutatakse pakkumise vormi nuppu
  $('#offerTabButton').click(function() {
    if ($("#offerTab").hasClass("active")) {
      return;
    }
    // Kui avatakse pakkumise vormi vaheleht
    else {
      jQuery("#selectOptions").empty();
      addLoadingIcon();
      populateModels();
      trackConv('942460562','3yQlCN2B6l8Qkp2zwQM');
      ga('send', 'event', 'button', 'click', 'askoffer',1);
    }
  });

  $('#offerSubmit').click(function() {
    ga('send', 'event', 'button', 'click', 'askoffersubmit',1);
  });

  // Kui proovisõiduvorm on lehe laadimisel aktiivne
  if ($("#testdriveTab").hasClass("active")) {
    populateModels(true);
  }

  // Kui vajutatakse proovisõiduvormi nuppu
  $('#testdriveButton').click(function() {
    if ($("#testdriveTab").hasClass("active")) {
      return;
    }
    else {
      populateModels(true);
      trackConv('942460562','Z6OdCK_17l8Qkp2zwQM');
      ga('send', 'event', 'button', 'click', 'testdrive',1);
    }
  });

  $('#testdriveSubmit').click(function() {
    ga('send', 'event', 'button', 'click', 'testdrivesubmit',1);
  });

  $('#models').change(function($) { // Kui mudelite rippmenüüs valitakse mingi mudel
    populateModelPacks();
    populateModelOptions();
  });

  $('#testdrivemodels').change(function($) { // Kui proovisõiduvormi mudelite rippmenüüs valitakse mingi mudel
    populateModelPacks(true);
  });

  $('#modelConfigurations').change(function($) {
    populateModelOptions();
  });

  // Book Service clicks
  $('#serviceTabButton').click(function() {
    ga('send', 'event', 'button', 'click', 'bookservice',1);
  });

  $('#bookserviceSubmit').click(function() {
    ga('send', 'event', 'button', 'click', 'bookservicesubmit',1);
  });
});

// Sisu sees päringuvormidele viitavad lingid avavad vastava vormi
jQuery(document).ready(function($) {
  $( "#content a" ).click(function( event ) {
    var queryForms = ["#service", "#offer", "testdrive"];
    if ($.inArray($(this).attr('href'), queryForms)>-1) {
      event.preventDefault();
      var href = $(this).attr('href');
      var button = $(href.replace(/#{1}(\w+)/,'#$1TabButton'));
      var content = $(href.replace(/#{1}(\w+)/,'#$1Tab'));
      openTab(button,content);
    }
  });
});

function openTab(button,content) {
  button.addClass('active').siblings().removeClass('active');
  content.addClass('active').show().siblings().removeClass('active').hide();
}

// // Hoolduse vormil kuupäeva välja peitmine / näitamine
// function setServiceDateVisibility() {
//   jQuery("input[name='bookorquote'][value='Küsin hinnapakkumist']").each(function(){
//     if (jQuery(this).prop("checked")===true) {
//       jQuery(this).parents('.wpcf7-form').find('.servicedate').hide();
//     }
//     else {
//       jQuery(this).parents('.wpcf7-form').find('.servicedate').show();
//     }
//   });
// }

// // Valiku muutmisel vormis
// jQuery(document).ready(function($) {
//   jQuery("input[name='bookorquote']:radio").change( function(event){
//     setServiceDateVisibility();
//   });
// });

// Škoda teeninduse eripakkumise lahendus
jQuery(document).ready(function($) {
  $("#skodaquote input[name='make'][value='Škoda']").prop("checked", true);
  $("#skodaquote input[name='bookorquote'][value='Küsin hinnapakkumist']").prop("checked", true);
  // setServiceDateVisibility();
});

jQuery(document).ready(function($) {
  if ($('body').hasClass('single-special_offer') || $('body').hasClass('single-post')) {
      $('.ribbon-carousel').owlCarousel({
        margin: 10,
        height: 200,
        autoWidth: true,
        nav: true
    });
  }
});

// Klienditsitaatide slaideri käivitamine esilehel
jQuery(document).ready(function($) {
  if ($("#quotes-scroller").length) {
    $("#quotes-scroller").owlCarousel({

      autoplay: true,
      autoplayTimeout: 5000, //Set AutoPlay to 3 seconds
      autoplayHoverPause: true,

      loop:true,
      margin:10,
      responsiveClass:true,
      responsive:{
        0:{
            items:1,
            nav:false
        },
        768:{
            items:2,
            nav:false
        },
        1201:{
            items:3,
            nav:true,
            loop:true
        }
      }
    });
  }
});

// Avalehe slaideri käivitamine
jQuery(document).ready(function($) {
  if ($('body').hasClass('home')) {

    var homePageSlider = $("#home-slider .front-page-slider").owlCarousel({
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      dotsContainer: '#dots',
      lazyLoad: true,
      lazyLoadEager: 1,
      onTranslated: animateSlide,
      onTranslate: removeAnimation
    });

    function removeAnimation() {
        var item = $('.owl-item .slide .slide__container');
        item.removeClass('animated fadeIn');
        item.addClass('hide');
    }

    function animateSlide() {
        var item = $('.owl-item.active .slide .slide__container');
        item.removeClass('hide');
        item.addClass('animated fadeIn');
    }
  }

});

// Varuosatarnijate slaideri käivitamine
jQuery(document).ready(function($) {
  if ($(".logos-scroller").length) {
    $(".logos-scroller").owlCarousel({

      autoplay: true,
      autoplayTimeout: 4000, // Set AutoPlay to 3 seconds
      autoplayHoverPause: true,

      loop:true,
      margin:10,
      responsiveClass:true,
      responsive:{
        0:{
          items:2,
          nav:false
        },
        768:{
          items:3,
          nav:false
        },
        1201:{
          items:4,
          nav:true,
          loop:true
        }
      }
    });
  }
});

// iPhone'ilikud raadionupud
jQuery(document).ready(function($) {
  // Lehe laadimisel antakse aktiivsetele raadionuppudele aktiivse iPhone tab'i välimus
  $(".btn-group .wpcf7-list-item").children().children("input:checked").parent().parent().addClass("active");

  // Raadioinputi vajutamisel muudetakse parent span class active'iks
  $(".btn-group input").on('change', function() {
    $(this).parent().parent().addClass("active");
    $(this).parent().parent().siblings().removeClass("active");
  });

  // Raadoinputi ümbritsevat span tag'i vajutades muudetakse raadioinput aktiivseks, muudetakse parent span class active'iks
  $(".btn-group .wpcf7-list-item").click(function() {
    var checkBoxes = $(this).children().children("input");
    checkBoxes.prop("checked", true);
    checkBoxes.parent().parent().addClass("active");
    checkBoxes.parent().parent().siblings().removeClass("active");
  });
});

// Leiunurk
jQuery(document).ready(function ($) {
  if ($("body").hasClass("page-template-leiunurk")) {
    populateLeiunurk();
  }
});

// Get Url Parametres function
function gup( name, url ) {
  if (!url) url = location.href;
  name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
  var regexS = "[\\?&]"+name+"=([^&#]*)";
  var regex = new RegExp( regexS );
  var results = regex.exec( url );
  return results === null ? null : results[1];
}

// Table fixed header

;(function(jQuery) {
   $.fn.fixMe = function() {
      return this.each(function() {
       var $this = $(this),
          $t_fixed;
       function init() {
          $this.wrap('<div class="container" />');
          $t_fixed = $this.clone();
          $t_fixed.find("tbody").remove().end().addClass("fixed").insertBefore($this);
          resizeFixed();
       }
       function resizeFixed() {
          $t_fixed.find("th").each(function(index) {
             $(this).css("width",$this.find("th").eq(index).outerWidth()+"px");
          });
       }
       function scrollFixed() {
          var offset = $(this).scrollTop(),
          tableOffsetTop = $this.offset().top,
          tableOffsetBottom = tableOffsetTop + $this.height() - $this.find("thead").height();
          if(offset < tableOffsetTop || offset > tableOffsetBottom)
             $t_fixed.hide();
          else if(offset >= tableOffsetTop && offset <= tableOffsetBottom && $t_fixed.is(":hidden"))
             $t_fixed.show();
       }
       $(window).resize(resizeFixed);
       $(window).scroll(scrollFixed);
       init();
    });
   };
})(jQuery);

jQuery(document).ready(function(){
  $("table.fixed-top").fixMe();
});

jQuery(document).ready(function(){

  // Uued autod mudelivaade
  if ($('body').hasClass('single-new_car')) {
    // Owl Carousel
    $('.owl-carousel.ribbon-carousel.owl-video').owlCarousel({
      margin: 10,
      height: 180,
      autoWidth: true,
      video: true,
      nav: false
    });

    // Lightgallery galerii
    $("#lightgallery").lightGallery({
      selector: '.gallery-thumbnail-link',
      download: false,
      thumbnail: true,
      thumbWidth: 140,
      exThumbImage: 'data-thumb',
      hash: false
    });

    // Ava lightgallery esimese slaidiga (mudelivaates)
    $('.open-gallery').on('click', function() {
      $('#lightgallery figure:first-of-type .gallery-thumbnail-link').trigger('click');
    });

    // Lightgallery for videos
    var $lightGalleryVideos = $(".owl-carousel.ribbon-carousel.owl-video");
    $lightGalleryVideos.lightGallery({
      selector: '.item',
      download: false,
      thumbnail: true,
      thumbWidth: 140,
      exThumbImage: 'data-thumb',
      hash: false,
      videoMaxWidth: '1024px',
      autoplayControls: false
    });

    // Lightgallery thumbnails IE object-fit fix
    var userAgent, ieReg, ie;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    ie = ieReg.test(userAgent);

    if ( ie ) {
      $lightGalleryVideos.on('onAfterOpen.lg', function(e){
        $(".lg-outer .lg-thumb-item").each(function () {
          var $container = $(this),
              imgUrl = $container.find("img").prop("src");
          if ( imgUrl ) {
            $container.css("background-image", 'url(' + imgUrl + ')').addClass("custom-object-fit");
          }
        });
      });
    }
  }

  // Lightgallery multiple instances
  $('.owl-carousel.ribbon-carousel.lightgallery').each(function(i) {
    $(this).lightGallery({
        selector: $(this).find('.owl-figure > a'),
        galleryId: i
    });
  })

});